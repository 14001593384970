import { Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import OutboundLink from '../../components/OutboundLink';
import Section from '../../components/Section';
import Modal from '../../components/Modal';

const FinancialMarketsPage = () => (
  <Layout title="Financial Markets">
    {/* FINANCIAL MARKETS HERO */}
    <Section className="hero">
      <Container>
        <Row>
          <Column size={12}>
            <Image
              filename="img-hero-financial-markets.jpg"
              alt="Financial Markets Hero Image"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* INTRO SECTION */}
    <Section className="intro-section" id="intro-section">
      <Container>
        <Row>
          <Column size={10}>
            <h1 className="page-title">Financial Markets</h1>
            <h2 className="page-subhead">Building a Foundation of Finance</h2>
            <p>
              Empower students to navigate their world and get prepared for
              success by understanding the core economic principle of finance
              through the lens of financial markets. Explore a series of
              interactive evidence-based resources that examine personal finance
              connections within the larger context of the global financial
              market.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
{/* THREE-COLUMN ASSETS*/}
    <Section id="guides" className="four-column-assets">
      <Container>
        <Row className="assets">
          <Column size={4} className="asset multifile box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-FM-card-featured-EdGuide.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Educator Guide</h4>
              <h3 className="asset__title">
                The Futures Marketplace: An Introduction for Educators
              </h3>
              <p>
                <strong>Topic:</strong> Finance | <strong>Grade:</strong> 9–12
              </p>
              <p>
                <strong>
                  Understanding the futures market allows teachers to provide
                  students with insight into complex economic systems, fostering
                  critical thinking and real-world financial awareness.
                </strong>
              </p>
              <p className="asset__description">
                Learn more about futures contracts and the crucial role that the
                derivatives marketplace plays in the global economy. Discover
                the origins of futures contracts in the world of agriculture and
                beyond, and find out how businesses and individuals can manage
                financial risk through the futures marketplace.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="/pdfs/3_financial-markets/CME-EconEssentials-EdGuide.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column
            size={4}
            id="futures-markets-and-risk"
            className="asset multifile box-shadow br-all"
          >
            <Image
              className="asset__image br-tl br-tr"
              filename="img-FM-card-featured-MiniDLB1.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Mini Digital Lesson Bundle</h4>
              <h3 className="asset__title">
                Futures Markets and Managing Risk
              </h3>
              <p>
                <strong>Topic:</strong> Finance | <strong>Grade:</strong> 9-12
              </p>
              <p>
                <strong>
                  How do financial risks and futures contracts affect students'
                  everyday lives?
                </strong>
              </p>
              <p className="asset__description">
                Find out how much preference influences people’s final decisions
                with a hands-on lesson exploring the way financial losses and
                gains impact human behavior.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="/pdfs/3_financial-markets/CME-EconEssentials-DLB-1-FuturesMarketsandManagingRisk.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
              <OutboundLink
                to="https://d2nso076wm43cb.cloudfront.net/CME-Econ-Essentials/CME-DLB-1-FuturesMarketsAndManagingRisk-FINAL.pptx"
                target="_blank"
                className="asset__file ppt"
              >
                <Icon name="download" />
                PowerPoint Presentation (.ppt)
              </OutboundLink>
            </div>
          </Column>
          <Column
            size={4}
            id="futures-trading"
            className="asset multifile box-shadow br-all"
          >
            <Image
              className="asset__image br-tl br-tr"
              filename="img-FM-card-featured-MiniDLB2.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Mini Digital Lesson Bundle</h4>
              <h3 className="asset__title">Futures Trading</h3>
              <p>
                <strong>Topic:</strong> Finance | <strong>Grade:</strong> 9-12
              </p>
              <p>
                <strong>
                  Navigating Futures Markets Through Snapshots and Analysis
                </strong>
              </p>
              <p className="asset__description">
                Immerse students in the world of futures markets and learn how
                traders make informed decisions by analyzing data, market
                trends, and current events. Kickstart the lesson with a simple
                game as students discover the ways that hedgers and speculators
                help bring balance to the futures marketplace using fundamental
                analysis and technical analysis.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="/pdfs/3_financial-markets/CME-EconEssentials-DLB-2-FuturesTrading.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
              <OutboundLink
                to="https://d2nso076wm43cb.cloudfront.net/CME-Econ-Essentials/CME-DLB-2-FuturesTrading-FINAL.pptx"
                target="_blank"
                className="asset__file ppt"
              >
                <Icon name="download" />
                PowerPoint Presentation (.ppt)
              </OutboundLink>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FOUNDATIONS OF FINANCE */}
    <Section className="intro-videos pb-1">
      <Container>
        <Row>
          <Column size={6} className="intro-videos-left pr-0">
            <Image filename="img-FM-foundations-of-finance.jpg" />
          </Column>
          <Column size={6} className="intro-videos-right box-shadow">
            <p className="resource-card__type">Self-Paced Module</p>
            <h3>Foundations of Finance</h3>
            <p>
              <strong>Topic:</strong> Finance | <strong>Grade:</strong> 9–12
            </p>
            <p>
              <strong>
                How much can interest rates impact personal finances?
              </strong>
            </p>
            <p>
              Watch how students’ fortunes grow—or shrink—based on the choices
              they make in this hands-on simulation! Students must navigate the
              tricky financial decisions that arise after graduating from high
              school, from student loans to buying a car.
            </p>
            <div className="asset__files layout-row">
              <OutboundLink
                to="/files/InteractiveModules/foundations-of-finance/index.html"
                className="asset__file"
                data-click-type="Module Launch"
                data-click-name={'Foundations of Finance'}
              >
                <Icon name="play" /> Self-Paced Module
              </OutboundLink>
              <OutboundLink
                to="/pdfs/3_financial-markets/CME-EconEssentials-SPMEdGuide-FoundationsofFinance.pdf"
                className="asset__file"
              >
                <Icon name="download" /> Educator Guide (.pdf)
              </OutboundLink>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO VIEWPOINT SERIES */}
    <Section className="video-series">
      <Container>
        <Row>
          <Column size={6} className="left">
            <h2>Real-World Connections from Classroom to Career</h2>
            <p>
              <b>Topic:</b> Futures Markets | <b>Grades:</b> 9-12
            </p>
            <p>
              Hear from young professionals in the finance industry as they share their inspiring career journeys. Discover why they chose finance and how their teachers made economics come to life in the classroom.
            </p>
            <Modal className="video-modal"
              trigger={
                <a className="button mt-1 mr-1">
                  Watch Now <Icon name="caratright" />
                </a>
              }
              video={true}
            >
                <div className='video'>
                    <iframe
                        src='https://app.discoveryeducation.com/learn/embedded-player/f8c231cc-408b-4c6d-83bf-c58b90e030e8?utm_source=microsite&utm_medium=embed'
                        title='Simplify the Complexity of Finance'
                        width="560"
                        height="349"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </Modal>
          </Column>
          <Column size={6} className="right" style={{ maxHeight: '450px' }}>
            <Image
              filename="featureRowImage-FinLitDay2024.jpg"
              alt="Classroom-Ready Lessons"
              style={{ height: '100%' }}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* STANDARDS-ALIGNED RESOURCES  */}
    <Section className="standards-aligned-resourses">
      <Container>
        <Row>
          <Column size={9}>
            <h2>Standards-Aligned Resources</h2>
            <h3>Build your lessons with handy standards guide</h3>
            <p>
              Econ Essentials resources are created in accordance with national
              education standards. Check out our standards-aligned tools to help
              build your lessons.
            </p>
            <a href="#sar-modal" className="button mt-1">
              Learn More <Icon name="caratright" />
            </a>
          </Column>
          <Column size={3}>
            <Image filename="img-standards-aligned-resources.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SUPPORTING RESOURCES */}
    <Section className="four-column-assets">
      <Container>
        <Row>
          <h2>Supporting Resources</h2>
        </Row>
        <Row className="assets horizontal">
          <Column className="asset br-tl bb-blue">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-educator-roadmap.jpg"
            />
            <div className="asset__content">
              <h3 className="asset__title">
                Career & Technical Education Educator Roadmap
              </h3>
              <p className="asset__description">
                Econ Essentials resources can be used in a variety of subject
                areas—everything from economics and social studies to personal
                finance and business to career and technical education (CTE) and
                family and consumer sciences (FCS).
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-CTERoadMap.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
          <Column className="asset br-tl bb-blue">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-investment-clubs.jpg"
            />
            <div className="asset__content">
              <h3 className="asset__title">
                Econ Essentials and Other Resources for Student Investment Clubs
              </h3>
              <p className="asset__description">
                Explore core economics concepts outside the classroom with Econ
                Essentials resources that are designed to work in afterschool
                clubs.
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-IvestmentClubRoadMap.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* QUOTE CALLOUT */}
    <Section className="quote-callout">
      <Container>
        <Row>
          <Column size={7} className="quote-callout__text">
            <h3 className="quote-callout__quote">
              “It has helped me understand the difference between different
              kinds of loans, and I may be needing them depending on what
              college I decide to attend.”
            </h3>
            <p className="quote-callout__source">
              <span className="quote-callout__source-name">Elena Sanchez</span>
              <br />
              Student, Ogden International School
              <br />
              Chicago, Illinois
            </p>
          </Column>
          <Column size={5} className="quote-callout__image pt-3 pb-3">
            <Image filename="img-fm-banner-quote.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FUTURES FUNDAMENTALS FOOTER IMAGE*/}
    <Section className="above-footer-banner">
      <Container>
        <Row>
          <Column>
            <Image
              filename="financial-markets-above-footer-banner.jpg"
              className="bb-orange"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SIGNUP FOR UPDATES */}
    <Section className="signup-updates">
      <Container>
        <Row>
          <Column size={7}>
            <h2>Sign Up for Updates</h2>
            <p>
              Want to be among the first to learn about exciting new resources
              and upcoming learning opportunities? Stay connected with Econ
              Essentials and sign up to receive updates today!
            </p>
            <OutboundLink
              to="https://info.discoveryeducation.com/Econ-Essentials-Powered-by-Futures-Fundamentals_Opt-Ins.html"
              className="button mt-1"
            >
              Learn More <Icon name="caratright" />
            </OutboundLink>
          </Column>
          <Column size={4} offset={1}>
            <Image filename="hp-signip-map.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP |
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default FinancialMarketsPage;
